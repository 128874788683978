.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.flexbtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  font-size: 24px;
}

.contact {
  font-size: 20px;
  font-weight: bold;
}

/*tab css*/
.tab {
  float: left;
  width: 100%;
  height: 290px;
}

.tabnav {
  font-size: 0;
  border: 1px solid #ddd;
}

.tabnav li {
  display: inline-block;
  height: 46px;
  text-align: center;
  border-right: 1px solid #ddd;
}

.tabnav li a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3px;
}

.tabnav li a.active:before {
  background: #7ea21e;
}

.tabnav li a.active {
  border-bottom: 1px solid #fff;
}

.tabnav li a {
  position: relative;
  display: block;
  background: #f8f8f8;
  color: #000;
  padding: 0 30px;
  line-height: 46px;
  text-decoration: none;
  font-size: 16px;
}

.tabnav li a:hover,
.tabnav li a.active {
  background: #fff;
  color: #7ea21e;
}

.tabcontent {
  padding: 20px;
  border: 1px solid #ddd;
  border-top: none;
}

.tabimg {
  display: block;
  object-fit: cover;
  border-radius: 1px;
  pointer-events: auto;
  width: 100%;
  border: 1px solid #7ea21e;
}

.codecss {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  font-size: 85%;
  tab-size: 2;
  min-height: 1em;
  color: rgb(55, 53, 47);
  white-space: pre-wrap;
  word-break: break-all;
  min-width: 0px;
}